<template>
  <div
    v-if="section && section.children?.length"
    class="sidebar-section"
  >
    <nav>
      <KButton
        appearance="none"
        class="section-title"
        type="button"
        @click="expanded = !expanded"
      >
        <div>{{ section.title }}</div>
        <component
          :is="expanded ? ChevronDownIcon : ChevronRightIcon"
          as="div"
        />
      </KButton>
      <Transition name="slide">
        <div
          v-show="expanded"
          class="section-links"
        >
          <NuxtLink
            v-for="childItem in section.children"
            :key="childItem.path"
            :href="childItem._path"
            prefetch
          >
            <span>{{ childItem.title }}</span>
          </NuxtLink>
        </div>
      </Transition>
    </nav>
  </div>
</template>

<script setup lang="ts">
import { ChevronDownIcon, ChevronRightIcon } from '@kong/icons'
import type { NavItem } from '@nuxt/content'

const props = defineProps({
  section: {
    type: Object as PropType<NavItem>,
    required: true,
  },
})

const expanded = useState(`docs-sidebar-expanded-${props.section._path}`, () => true)
</script>

<style lang="scss" scoped>
.sidebar-section {
  margin-bottom: $kui-space-50;

  &:last-of-type {
    margin-bottom: $kui-space-0;
  }
}

.section-title {
  align-items: center;
  color: $kui-color-text;
  display: flex;
  font-size: $kui-font-size-30;
  font-weight: $kui-font-weight-semibold;
  justify-content: space-between;
  line-height: $kui-line-height-30;
  margin-bottom: $kui-space-30;
  width: 100%;
}

.section-links {
  overflow: hidden;
  transform-origin: top;

  a {
    align-items: center;
    color: $kui-color-text-neutral;
    display: flex;
    font-size: $kui-font-size-30;
    line-height: $kui-line-height-30;
    margin: $kui-space-20 $kui-space-0 $kui-space-20 $kui-space-40;
    padding: $kui-space-10 $kui-space-0;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: $kui-color-text;
    }

    &.router-link-active {
      color: $kui-color-text-primary;
    }

    > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

// Vue `slide` animations
.slide-enter-active, .slide-leave-active {
  transition: max-height 0.3s ease;
}

.slide-enter-from, .slide-leave-to {
  max-height: 0;
}

.slide-enter-to, .slide-leave-from {
  max-height: 100vh;
}
</style>
