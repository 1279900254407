<template>
  <LayoutContent layout-name="docs">
    <template #left>
      <LayoutSidebarDocs />
    </template>

    <slot name="default" />

    <template #right>
      <LayoutSidebarToc />
    </template>
  </LayoutContent>
</template>

<script setup lang="ts">
import type { TocLink } from '@nuxt/content'
const route = useRoute()

// Fetch the page TOC here so that it's available for all child components
const { data: doc } = await useAsyncData(`layout-sidebar-toc-${route.path}`, () => queryContent(route.path).findOne(), {
  watch: [() => route.path],
})

// Initialize a ref that can be accessed in child components. The key must match as shown here.
const sidebarTocLinks = useState<TocLink[] | undefined>('layout-sidebar-toc', () => undefined)
// Update the links whenever the page TOC changes
watch(() => doc.value?.body?.toc?.links, (pageToc?: TocLink[]) => {
  sidebarTocLinks.value = pageToc
}, { immediate: true, deep: true })
</script>
