import { useWindowScroll, useWindowSize } from '@vueuse/core'

/**
 * Binds an IntersectionObserver to the headings in the sidebar TOC to determine the active link.
 *
 * @param {HTMLElement} tocList - The TOC list element
 * @param {number} depth - The depth of Headings to observe. Default is 3.
 */
export default function useTocActiveLink(tocList: Ref<HTMLElement | null>, depth: number = 3) {
  // The active link ID
  const activeLinkId = useState<string | null>('toc-active-link-id', () => null)
  // The scrollable content container (where the headings are located)
  const scrollContainerSelector = '.layout-content-container'
  const observer = useState<IntersectionObserver | null | undefined>('toc-observer', () => null)
  const observerOptions = {
    root: tocList.value,
    threshold: 1,
    // -60px = Navbar height, -75% = meaning the header needs to be at the top 30% of the viewport to be made active
    rootMargin: '-60px 0px -75% 0px',
  }

  const bindIntersectionObserver = (): void => {
    if (import.meta.client) {

      if (observer.value) {
        observer.value.disconnect()
      }

      const headingsQuerySelectors: string[] = []
      for (let i = 1; i <= depth; i++) {
        // Skip the H1 headings
        if (i === 1) continue
        headingsQuerySelectors.push(`${scrollContainerSelector} h${i}[id]`)
      }

      const headings = document.querySelectorAll<HTMLHeadingElement>(headingsQuerySelectors.join(', '))
      const headingsArray = Array.from(headings)

      // no headers available for active link
      if (!headings.length) {
        activeLinkId.value = null
        return
      }

      const { y: scrollY } = useWindowScroll()
      const { height: windowHeight } = useWindowSize()

      observer.value = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const id = entry.target.getAttribute('id')
          const entryIndex = headingsArray.findIndex(heading => heading.getAttribute('id') === id)
          const entryCurrentY = entry.boundingClientRect.y

          // Determine the document height
          const documentHeight = Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.body.clientHeight,
            document.documentElement.clientHeight,
          )

          // Determine if the user is scrolled to the bottom (minus an offset)
          const scrolledToBottom: boolean = scrollY.value + windowHeight.value >= documentHeight - 120

          if (scrolledToBottom === true) {
            // If user is at the bottom of the page, update to the last header
            activeLinkId.value = headingsArray[headingsArray.length - 1]?.getAttribute('id')
          } else if (entry.isIntersecting && entry.intersectionRatio === 1) {
            // Header at 30% from the top, update to current header
            activeLinkId.value = id
          } else if (
            !entry.isIntersecting
            && entry.intersectionRatio < 1
            && entry.intersectionRatio > 0
            // If it's below TOC
            && !(entryCurrentY < (tocList.value?.getBoundingClientRect()?.y || 0))
          ) {
            // Previous Section Content is now visible, update to previous header
            const previousHeaderId = headingsArray[entryIndex - 1]?.getAttribute('id')
            // If currentVisible == undefined, ToC component will fallback to show 'Table of Contents'
            activeLinkId.value = previousHeaderId
          }
        })
      }, observerOptions)

      // If we display more depth in the TOC, we'll need to add the headers here
      headings.forEach((heading) => {
        observer.value?.observe(heading)
      })
    }
  }

  const initializeObserver = async (): Promise<void> => {
    await nextTick()
    setTimeout(() => {
      bindIntersectionObserver()
    }, 500)
  }

  // Initialise onMounted
  onMounted(async () => {
    await initializeObserver()
  })

  // Initialize onBeforeUpdate (e.g. route change)
  onBeforeUpdate(async () => {
    await initializeObserver()
  })

  // Cleanup onUnmounted
  onUnmounted(() => {
    observer.value?.disconnect()
  })

  return {
    activeLinkId,
    refresh: initializeObserver,
  }
}
