<template>
  <aside
    v-if="sidebarTocLinks?.length"
    :class="['layout-sidebar-toc', { open: sidebarTocIsVisible }]"
  >
    <LayoutSidebarHeader
      align-title="center"
      data-testid="layout-sidebar-toc-header"
      :title="t('navigation.on_this_page')"
      @close="sidebarTocIsVisible = false"
    />
    <div class="sidebar-content">
      <nav class="sidebar-toc">
        <div class="toc-header">
          {{ t('navigation.on_this_page') }}
        </div>
        <ol ref="tocList">
          <LayoutSidebarTocItem
            v-for="link in sidebarTocLinks"
            :key="link.id"
            :active-link-id="activeLinkId"
            :link="link"
          />
        </ol>
      </nav>
    </div>
  </aside>
</template>

<script setup lang="ts">
import type { TocLink } from '@nuxt/content'

const tocList = useState<HTMLOListElement | null>(() => null)
const { activeLinkId, refresh } = useTocActiveLink(tocList)
const { t } = useI18n()

const { sidebarTocIsVisible } = storeToRefs(useLayoutStore())
const sidebarTocLinks = useState<TocLink[] | undefined>('layout-sidebar-toc', () => undefined)

// !Important: When the provided links change, refresh the active link observer
watch(sidebarTocLinks, async () => {
  await refresh()
}, { deep: true })

onMounted(async () => {
  await refresh()
})
</script>

<style lang="scss" scoped>
.layout-sidebar-toc {
  @include sticky-sidebar-base('right');
}

.sidebar-toc {
  padding: $kui-space-0 $kui-space-70;

  @media (min-width: $kui-breakpoint-laptop) {
    padding-left: $kui-space-0;
    padding-top: $kui-space-0;
  }
}

.toc-header {
  align-items: flex-start;
  display: none;
  font-size: $kui-font-size-30;
  font-weight: $kui-font-weight-medium;
  gap: $kui-space-20;
  justify-content: space-between;
  padding-bottom: $kui-space-30;
  white-space: nowrap;
  width: 100%;

  @media (min-width: $kui-breakpoint-tablet) {
    display: flex;
  }
}

.close-button {
  @media (min-width: $kui-breakpoint-tablet) {
    display: none;
  }
}

ol,
:deep(ol) {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

ol li :deep(ol) {
  margin-left: var(--kui-space-80, $kui-space-80);
}
</style>
