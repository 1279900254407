<template>
  <aside :class="['layout-sidebar-docs', { open: sidebarLayoutDocsIsVisible }]">
    <LayoutSidebarHeader
      align-title="center"
      data-testid="layout-sidebar-docs-header"
      :title="t('navigation.docs')"
      @close="sidebarLayoutDocsIsVisible = false"
    />
    <div class="sidebar-content">
      <nav class="sidebar-sections">
        <LayoutSidebarDocsSection
          v-for="section in sections"
          :key="section._path"
          :section="section"
        />
      </nav>
    </div>
  </aside>
</template>

<script setup lang="ts">
const { t } = useI18n()

const { data: sections } = await useAsyncData('layout-sidebar-docs-sections', () => fetchContentNavigation(queryContent({
  where: [{
    $or: [
      { _path: { $icontains: '/getting-started' } },
      { _path: { $icontains: '/components' } },
    ],
  }],
})))

const { sidebarLayoutDocsIsVisible } = storeToRefs(useLayoutStore())
</script>

<style lang="scss" scoped>
.layout-sidebar-docs {
  @include sticky-sidebar-base('left');
}

.sidebar-sections {
  padding: $kui-space-0 $kui-space-70;
  width: 100%;
}
</style>
